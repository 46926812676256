body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.loading-container {
  background-color: #bc020f;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  -webkit-animation: 5s annimationComplete ease-out forwards;
  animation: 5s annimationComplete ease-out forwards;
}

.cls-1 {
  opacity: 0; }

.cls-2 {
  opacity: 1; }

svg path {
  fill: #000; }

.svg-animation {
  padding: 500px;
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: 4s fillScreen ease-out forwards;
  animation: 4s fillScreen ease-out forwards;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color:#bc020f;
  pointer-events: none;
}

.textLogoCenter {
  stroke: #000;
  stroke-width: 2px;
  stroke-dasharray: 655px;
  stroke-dashoffset: -655px;
  -webkit-animation: 4s animateText ease-out forwards;
  animation: 4s animateText ease-out forwards;
  fill: transparent !important;
}

#svg_logo {
  height: 500px;
  -webkit-transform: scale3d(0.2, 0.2, 0.2);
  transform: scale3d(0.2, 0.2, 0.2);
  overflow: visible;
  -webkit-animation: 4s scaleSVG ease-out forwards;
  animation: 4s scaleSVG ease-out forwards; }

.long_arrow {
  opacity: 1;
  -webkit-animation: 4s rotateArrow ease-in-out forwards;
  animation: 4s rotateArrow ease-in-out forwards;
  -webkit-transform-origin: 55% 54%;
  -ms-transform-origin: 55% 54%;
  transform-origin: 55% 54%;
  -webkit-transform: translateZ(0) rotate(-130deg);
  -ms-transform: translateZ(0) rotate(-130deg);
  transform: translateZ(0) rotate(-130deg); }

@-webkit-keyframes animateText {
  0% {
    stroke-dasharray: 655px;
    stroke-dashoffset: 655px;
    fill: transparent; }
  33% {
    stroke-dashoffset: 0;
    fill: transparent; }
  35%, 100% {
    stroke-dashoffset: 0;
    fill: #000; } }
@keyframes animateText {
  0% {
    stroke-dasharray: 655px;
    stroke-dashoffset: 655px;
    fill: transparent; }
  33% {
    stroke-dashoffset: 0;
    fill: transparent; }
  35%, 100% {
    stroke-dashoffset: 0;
    fill: #000; } }
@-webkit-keyframes rotateArrow {
  0% {
    -webkit-transform: translateZ(0) rotate(-130deg);
    transform: translateZ(0) rotate(-130deg); }
  15%, 100% {
    -webkit-transform: translateZ(0) rotate(0deg);
    transform: translateZ(0) rotate(0deg); } }
@keyframes rotateArrow {
  0% {
    -webkit-transform: translateZ(0) rotate(-130deg);
    transform: translateZ(0) rotate(-130deg); }
  15%, 100% {
    -webkit-transform: translateZ(0) rotate(0deg);
    transform: translateZ(0) rotate(0deg); } }
.dot-1 {
  -webkit-animation: 4s dot-1 ease-out forwards;
  animation: 4s dot-1 ease-out forwards; }

.dot-2 {
  -webkit-animation: 4s dot-2 ease-out forwards;
  animation: 4s dot-2 ease-out forwards; }

.dot-3 {
  -webkit-animation: 4s dot-3 ease-out forwards;
  animation: 4s dot-3 ease-out forwards; }

.dot-4 {
  -webkit-animation: 4s dot-4 ease-out forwards;
  animation: 4s dot-4 ease-out forwards; }

.dot-5 {
  -webkit-animation: 4s dot-5 ease-out forwards;
  animation: 4s dot-5 ease-out forwards; }

.dot-6 {
  -webkit-animation: 4s dot-6 ease-out forwards;
  animation: 4s dot-6 ease-out forwards; }

@-webkit-keyframes dot-1 {
  0% {
    opacity: 0; }
  7.5%, 100% {
    opacity: 1; } }
@keyframes dot-1 {
  0% {
    opacity: 0; }
  7.5%, 100% {
    opacity: 1; } }
@-webkit-keyframes dot-2 {
  0%,3.5% {
    opacity: 0; }
  12.5%, 100% {
    opacity: 1; } }
@keyframes dot-2 {
  0%,3.5% {
    opacity: 0; }
  12.5%, 100% {
    opacity: 1; } }
@-webkit-keyframes dot-3 {
  0%, 10.5% {
    opacity: 0; }
  17.5%, 100% {
    opacity: 1; } }
@keyframes dot-3 {
  0%, 10.5% {
    opacity: 0; }
  17.5%, 100% {
    opacity: 1; } }
@-webkit-keyframes dot-4 {
  0%, 14% {
    opacity: 0; }
  22.5%, 100% {
    opacity: 1; } }
@keyframes dot-4 {
  0%, 14% {
    opacity: 0; }
  22.5%, 100% {
    opacity: 1; } }
@-webkit-keyframes dot-5 {
  0%, 17.5% {
    opacity: 0; }
  27.5%, 100% {
    opacity: 1; } }
@keyframes dot-5 {
  0%, 17.5% {
    opacity: 0; }
  27.5%, 100% {
    opacity: 1; } }
@-webkit-keyframes dot-6 {
  0%, 21% {
    opacity: 0; }
  32.5%, 100% {
    opacity: 1; } }
@keyframes dot-6 {
  0%, 21% {
    opacity: 0; }
  32.5%, 100% {
    opacity: 1; } }
@-webkit-keyframes fillScreen {
  0%, 80% {
    background-color: transparent; }
  95%, 100% {
    background-color: black; } }
@keyframes fillScreen {
  0%, 80% {
    background-color: transparent; }
  95%, 100% {
    background-color: black; } }
@-webkit-keyframes scaleSVG {
  45% {
    -webkit-transform: translateZ(0) rotate(0) scale3d(0.2, 0.2, 0.2);
    transform: translateZ(0) rotate(0) scale3d(0.2, 0.2, 0.2);
    background-color: transparent; }
  95% {
    -webkit-transform: translateZ(0) scale3d(10, 10, 10) rotate(40deg);
    transform: translateZ(0) scale3d(10, 10, 10) rotate(40deg);
    background-color: #000; } }
@keyframes scaleSVG {
  45% {
    -webkit-transform: translateZ(0) rotate(0) scale3d(0.2, 0.2, 0.2);
    transform: translateZ(0) rotate(0) scale3d(0.2, 0.2, 0.2); }
  65% {
    background-color: transparent; }
  95% {
    -webkit-transform: translateZ(0) scale3d(10, 10, 10) rotate(40deg);
    transform: translateZ(0) scale3d(10, 10, 10) rotate(40deg);
    background-color: #000; } }


@-webkit-keyframes annimationComplete {
  0%,90% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
    pointer-events: none;
  }
}
@keyframes annimationComplete {
  0%,60% {
    opacity: 1;
    height:100%;
    width: 100%;
  }
  99% {
    opacity: 0;
    height:100%;
    width: 100%;
  }
  100% {
    opacity: 0;
    display: none;
    pointer-events: none;
    height:0;
    width: 0;
  }
}