body,
html {
  height: 100%;
  margin: 0;
}

body {
  background: rgba(0,0,0,0.9);;
  color: white;
  font-family: Helvetica;
  overflow-x: hidden;
}

.App {
  margin: 0 auto;
}

.h-100 {
  height:100%;
}
.d-none {
  display: none!important;
}


.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.align-items-center{
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ratio-16-9 {
  padding-top: 56.25%;
  position: relative;
  height:auto!important;
}

.ratio-21-9 {
  padding-top: 42.85%;
  position: relative;
  height:auto!important;
}

@media (orientation: portrait) {
  .popular-background.ratio-21-9 {
    padding-top: 56.25%;
  }
}

/*header*/
a {
  color: #bc020f;
}
nav {
  color: #bc020f;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


#header-nav {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: background-color 0.8s;
  -o-transition: background-color 0.8s;
  transition: background-color 0.8s;
  padding: 0px 15px;
}

@media screen and (min-width: 950px){
  #header-nav {
    padding: 10px 30px;
  }
  .video{
    margin-top:50px !important;
  }
}

#header-nav .nav-link {
  margin-left: 1.5rem;
  color: white;
  text-decoration: none;
  font-size: 0.8rem;
  text-shadow: 0 0 3px rgba(0,0,0,0.9);
}
#header-nav .nav-link:hover {
  font-weight: bold;
}

#header-nav.bg-dark {
  background-color: rgb(24, 24, 24);
}

.logo {
  float: left;
  font-size: 30px;
}

.nav-logo-svg:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.nav-logo-svg img {
  height: 20px;
  width: auto;
  display: block;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}

.nav-logo-svg  svg {
  height: 60px;
}
.nav-logo-svg .logo-cls-1{fill: #bc020f;}
.nav-logo-svg .logo-cls-2{fill: #bc020f;}
.nav-logo-svg .logo-cls-3{fill: #bc020f;}

.nav-dropdown {
  position: relative;
  margin-left:50px;
}
.nav-dropdown-btn {
  display: none;
  color: white;
  font-size: 0.8rem;
  text-decoration: none;
  margin-left: 1rem;
  min-height: 30px;
  line-height: 30px;
}

.logo a {
  color: #cc0000;
  text-decoration: none;
}

.logo a:hover {
  color: #e60000;
}

.top_gradient {
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.4)), to(rgba(0,0,0,0)));
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.4) 3%, rgba(0,0,0,0) 100%);
  background: -o-linear-gradient(top, rgba(0,0,0,0.4) 3%, rgba(0,0,0,0) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(3%, rgba(0,0,0,0.4)), to(rgba(0,0,0,0)));
  background: linear-gradient(to bottom, rgba(0,0,0,0.4) 3%, rgba(0,0,0,0) 100%);
  z-index: 1;
}

.search-bar {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.search-bar-dropdown-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  text-shadow: 0 0 5px rgba(0,0,0,0.7);
  margin-right: 0.5rem;
}

.search-input {
  background-color: rgba(0,0,0,0.7);
  color: #fff;
  border: 1px solid #cc0000;
  outline: none;
  border-radius: 8px;
  padding: 5px;
  padding-left: 15px;
  width: 250px;
  height: 20px;
  font-weight: 600;
  position: relative;
  display: none;
  margin-right: 10px;
}

.show-dropdown .search-input {
  display: block;
}

.search-values {
  background: #0d0d0d;
  width: 265px;
  border-radius: 5px;
  border: 2px solid #cc0000;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  left: 1.5rem;
  position: absolute;
  margin-left: 2px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  z-index: 1;
  top: 100%;
  max-height: 70vh;
  overflow: auto;

}
.search-values ul {
  margin: 0;
  padding: 10px;
  list-style: none;
}

.search-values ul li {
  margin: 8px;
}

.search-values a {
  text-decoration: none;
  color: #e60000;
}

.search-values a:hover {
  text-decoration: underline;
  font-weight: 600;
}

.bgImage {
  height: 750px;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white;
  z-index: 0;
}
.popular-background{
  background: #232323;
  /*margin-top: 55px;*/
  position: relative;
}
.popular{
  background: url(/rapflix-rf-logo.png);
  background-size: 200px;
  background-position: center;
  background-repeat: no-repeat;
  overflow:hidden;
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.popular .bgImage {
  height:100%;
  width: 100%;
}

.popularInfo {
  position: absolute;
  bottom: 7%;
  margin:4%;
  margin-bottom:0;
  width: 36%;
}

.header-home .popularInfo h1 {
  margin-bottom: 30px;

}


.popularInfo h1 {
  font-size: 2vw;
  margin: 0;
  /*-webkit-text-stroke: 1px black;*/
  text-shadow: 1px 1px 5px rgba(0,0,0,1);
  font-weight:normal;
}

@media only screen and (max-width: 690px){
  .popularInfo h1 {
    font-size: 4vw;
    margin: 0;
  }
  .popularInfo {
    position: absolute;
    bottom: 7%;
    margin: 4%;
    margin-bottom: 0;
    width: 92%;
  }

}

.switchImg {
  position: absolute;
  top: 90%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
}

.switchImg div {
  width: 30px;
  height: 10px;
  border: 1px solid #252525;
  background-color:#252525;
  border-radius: 2px;
  display: inline-block;
  margin: 20px;
  cursor: pointer;
}

.switchImg div.active {
  background: #bc020f;
}

/*lists of movies*/
/*.lists {
  margin: 100px 0;
}

.lists:first-child {
  margin-top: 50px;
}*/

.lists h2 {
  padding-left: 70px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
}
.lists>a>h3 {
  padding-left: 60px;
  margin-bottom: 10px;
  font-size: 15px;
}

.category-page .menu-item{
  float:left;
}

.category-page .content{
  text-align: center;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;}


.menu-item {
  padding: 0;
  /*margin: 5px 2px;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  border: none;
}
/*.menu-item-wrapper.active {*/
/*border: 1px blue solid;*/
/*}*/
.menu-item.active {
  border: 1px green solid;
}

.menu-wrapper--inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


.movie-card img {
  width: 225px;
  height: 126.56px;
  vertical-align: 0;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.movie-title {
  position: absolute;
  top: 80%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  white-space: initial;
  color: #fff;
  visibility: hidden;
  text-shadow: 1px 1px 5px rgba(0,0,0,1);

}
.movie-card-background{
  background: #232323;
}
.movie-card {
  position: relative;
  width: 225px;
  height: 126.56px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  background: url(/24hour-logo-white.png);
  background-size: 50px;
  background-position: center;
  background-repeat: no-repeat;  overflow:hidden;
}

.movie-card .imgEle {
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  opacity: 1;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.movie-card:hover .imgEle {
  opacity: 1;
}


.movie-card .movie-title h3{
  font-size: 14px;
  max-width: 300px;
  margin: 0 auto;
}


.movie-card img {
  opacity: 0.4;
  pointer-events: none!important;
}

.lists{
  margin-top:50px;
}

/**
 * MOBILE SPECIFIC
 */
.mobile-list .movie-title  {
  visibility: visible;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0.8)), to(rgba(0,0,0,0)));
  background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
  background: -o-linear-gradient(bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
  padding: 8%;
}

.mobile-list .row_slider__inner{
  margin:0
}

/**
 * NOT MOBILE SPECIFIC
 */
.lists:not(.mobile-list) .movie-card:hover {
  width: 375px;
  height: 177.63px;
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
  visibility: visible;
}
.lists:not(.mobile-list) .movie-card:hover .movie-title {
  visibility: visible;
  color:#fff;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0.8)), to(rgba(0,0,0,0)));
  background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
  background: -o-linear-gradient(bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
  padding: 8%;
}
.lists:not(.mobile-list) .movie-card:hover img {
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
  width: 375px;
  height: 177.63px;
  vertical-align: -12px;
  opacity: 0.2;
}

.row {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
  grid-gap: 10px;
}

.row .menu-item {
  margin:0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.img-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  background-size: cover !important;
}

/*.menu-item:hover .img-bg {*/
/*opacity: 0.5;*/
/*}*/

.movie-card2 {
  position: relative;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  background: black;
}

.menu-item a {
  text-decoration: none;
}


.movie-card2_wrapper .menu-item .movie-title {
  color: white;
  position: absolute;
  visibility: visible;
  opacity: 1;
  font-size: 0.9rem;
  text-decoration: none;
  -webkit-transition: color 0.8s;
  -o-transition: color 0.8s;
  transition: color 0.8s;
  margin: 0;
  text-align: left;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.25rem;
  background-color: rgba(0,0,0,0.8);
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  top:auto;
  font-weight: 600;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}



.menu-item:hover .movie-title {
  color: #bc020f;
}

.movie-card2:hover .movie-title {
  visibility: visible;
  opacity: 1;
}

.movie-card2:hover img {
  vertical-align: -12px;
  opacity: 0.3;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}



.arrow-prev,
.arrow-next {
  color: #bc020f;
  font-size: 40px;
}



.cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 50%;
  height: 100%;
}
.tv {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.strong{
  font-weight:bold;
}

.tv .screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  /*opacity: 0;*/
  /*transition: opacity 0.5s;*/
}
.details {
  padding-top: 50px;
  padding-left:2%;
  padding-right:2%;
  width: 46%;
  right: 0;
  position: absolute;
}
/*.tv .screen.active {*/
/*opacity: 1;*/
/*}*/





/* movie info */

.content {
  /*width: 90%;*/
  margin: 0 auto;
  padding:10px 30px;
  padding-bottom:20px;
  font-size: 16px;
  color: #e6e6e6;
  font-weight: 500;
  white-space: pre-line;
  position: relative;
}

.content h1 {
  margin: 0;
  font-size: 24px;
}

.content p {
  line-height: 19px;
  white-space: pre-line;
}
.videoBackground{
  background: #111111;
  /*margin-left: 5%;*/
  /*margin-right: 5%;*/
}
.video {
  padding-top: 42.85%;
  position: relative;
  -webkit-box-shadow: 0 0 30px grey;
  box-shadow: 0 0 30px grey;
  background: url(/rapflix-rf-logo.png);
  background-size: 100px;
  background-position: center;
  background-repeat: no-repeat;
  margin-top:35px;
}
.video img{
  width:100%;
  height:100%;
  position: absolute;
  top:0;
}

iframe.media {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.overview-container {
  margin-top: 10px;
}

.year-run-vote span {
  font-size: 14px;
  padding-right: 6px;
}

.run,
.vote {
  border-left: 1px solid white;
  padding-left: 6px;
}

.greyed {
  color: #9c9c9c;
}

i {
  color: #bc020f;
}

footer {
  height: 60px;
  background: black;
  color: white;
  /*border-top: 1px solid #bc020f;*/
  text-align: center;
}


footer a {
  color: #bc020f;
}
footer p {
  margin: 20px auto;
}

.back-height {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.movie-page .back-height {
  padding-top: 60px;

}

a .showOnLinkHover{
  visibility: hidden;
}

a:hover .showOnLinkHover{
  visibility: visible;
}


@media (max-width: 1580px) {
  .row {
    -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

}

@media (max-width: 1300px) {
  .row {
    -ms-grid-columns: 1fr 10px 1fr 10px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

}

@media only screen and (max-width: 900px) {
  /*header*/
  .bgImage {
    height: 700px;
  }

  .header-overview {
    display: none;
  }

  .row {
    -ms-grid-columns: 1fr 10px 1fr;
    grid-template-columns: 1fr 1fr ;
  }

  /*.popularInfo {*/
  /*text-align: center;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*margin-right: -50%;*/
  /*-webkit-transform: translate(-50%, -50%);*/
  /*-ms-transform: translate(-50%, -50%);*/
  /*transform: translate(-50%, -50%);*/
  /*width: 90%;*/
  /*}*/

  .switchImg div {
    margin: 10px;
  }

  /*lists of movies*/

  .lists {
    margin: 0;
  }

  .lists h2 {
    margin-bottom: 0;
  }

  .movie-card img {
    width: 230px;
    height: 140px;
  }

  .lists:not(.mobile-list) .movie-card:hover,
  .lists:not(.mobile-list) .movie-card:hover img {
    width: 260px;
    height: 160px;
  }

  .arrow-prev,
  .arrow-next {
    font-size: 20px;
  }

  .scroll-menu-arrow {
    padding: 10px;
  }

  /*movie-info*/
  .content {
    /*width: 91%;*/
    font-size: 93%;
  }

}

@media only screen and (max-width: 690px) {

  #header-nav .nav-dropdown-btn {
    display: block;
    text-shadow: 0 0 5px rgba(0,0,0,0.8);
  }

  #header-nav .show-dropdown .nav-link_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .logo,
  .search-bar {
    float: none;
  }

  .logo {
    text-align: center;
  }

  .search-input {
    width: 95%;
  }

  .search-values {
    width: 87%;
  }
  .bgImage {
    height: 400px;
    background-size: cover;
  }

  .popularInfo button {
    margin: 0;
  }

  .lists h2 {
    padding-left: 5px;
    margin-bottom: 10px;
  }

  .movie-card img {
    width: 190px;
    height: 100px;
  }

  .lists:not(.mobile-list) .movie-card:hover,
  .lists:not(.mobile-list) .movie-card:hover img {
    width: 220px;
    height: 120px;
  }

  .movie-title, .movie-title h3 {
    font-size:11px !important;
  }

  .horizontal-menu {
    padding: 0.5rem 0;
  }
}

@media (max-width: 600px) {
  .lists>a>h3 {
    padding-left: 10px;
    margin-bottom: 10px;
    font-size: 3vw;
  }
}


@media only screen and (max-width: 350px) {
  .back-height {
    height: 100%;
  }

  /*.popularInfo {*/
  /*text-align: center;*/
  /*top: 40%;*/
  /*left: 50%;*/
  /*margin-right: -50%;*/
  /*-webkit-transform: translate(-50%, -50%);*/
  /*-ms-transform: translate(-50%, -50%);*/
  /*transform: translate(-50%, -50%);*/
  /*width: 90%;*/
  /*}*/

  /* list of movies*/

  .horizontal-menu {
    height: 190px;
  }

  .movie-card img {
    width: 160px;
    height: 90px;
  }

  .lists:not(.mobile-list) .movie-card:hover,
  .lists:not(.mobile-list) .movie-card:hover img {
    width: 190px;
    height: 110px;
  }
}

@media only screen and (max-height: 800px) {
  .back-height {
    height: 100%;
  }
}



/*--------------- NEW SLIDER ---------------*/
.slider-container {
  padding: 0;
  padding-top: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.row_slider {
  overflow: scroll;
  scrollbar-width: none;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

/*ROW SCROLL BAR*/
.row_slider::-webkit-scrollbar,
.row_slider::-webkit-scrollbar-track,
.row_slider::-webkit-scrollbar-thumb,
.row_slider::-webkit-scrollbar-thumb:hover {
  opacity: 0;
  display: none;
}

.row_slider__inner {
  transition: 450ms -webkit-transform;
  -webkit-transition: 450ms -webkit-transform;
  -o-transition: 450ms transform;
  transition: 450ms transform;
  transition: 450ms transform, 450ms -webkit-transform;
  font-size: 0;
  white-space: nowrap;
  margin: 32.3125px 0;
  margin-left:10px !important;
  padding-bottom: 10px;
}
.row_slide__title_link {
  margin: 0;
  margin-left: 10px;
  margin-right:10px;
  color:#636363;
}

.row_slide__title_link i{
  color:#636363;
}

.tile {
  position: relative;
  display: inline-block;
  width: 300px;
  height: 169px;
  margin-right: 2px;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: 450ms all;
  -o-transition: 450ms all;
  transition: 450ms all;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
  overflow: hidden !important;
}

.tile-show {
  height:275px;
  background-size: cover!important;
  background-position: center center !important;
}

.tile-show  .tile__img{
  display: none;
}


.tile__img {
  width: 300px;
  height: 169px;
  -o-object-fit: cover;
  object-fit: cover;
}
.tile-show .tile__media{
  height:auto!important;
}
.tile__media {
  background: #232323 url(/24hour-logo-white.png);
  background-size: 50px;
  background-position: center;
  background-repeat: no-repeat;
  height:100%;
}
.tile__details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  font-size: 12px;
  opacity: 0;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0.8)), to(rgba(0,0,0,0)));
  background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 40%);
  background: -o-linear-gradient(bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 40%);
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 40%);
  -webkit-transition: 450ms opacity;
  -o-transition: 450ms opacity;
  transition: 450ms opacity;
}
.tile__details:after,
.tile__details:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
}

.tile:hover .tile__details {
  opacity: 1;
}
.tile__title {
  position: absolute;
  bottom: 0;
  padding: 5px;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: normal;
  color: white;
  text-shadow: 0 0 10px rgba(0,0,0,1);
}
.tile-show .tile__title{
  font-size:14px;

}
.slider-container:not(.mobile-list) .row_slider__inner:hover:not(:first-child) {
  -webkit-transform: translate3d(-62.5px, 0, 0);
  transform: translate3d(-62.5px, 0, 0);
}

.slider-container:not(.mobile-list) .row_slider__inner:hover .tile {
  opacity: 0.3;
}
.slider-container:not(.mobile-list) .row_slider__inner:hover .tile:hover {
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
  opacity: 1;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.6);
  z-index:1;
}

.slider-container:not(.mobile-list) .row_slider__inner:hover .tile-show:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 1;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
}

.slider-container:not(.mobile-list)  .tile:hover ~ .tile {
  -webkit-transform: translate3d(100px, 0, 0);
  transform: translate3d(100px, 0, 0);
}
.slider-container:not(.mobile-list)  .tile-show:hover ~ .tile-show {
  -webkit-transform: translate3d(50px, 0, 0);
  transform: translate3d(50px, 0, 0);
}

.mobile-list .tile__details {
  opacity: 1;
}
.slider-container.mobile-list .row_slider__inner {
  margin: 4.3125px 0;
}
/*PLAY BUTTON*/
/*.tile__details:after {*/
/*margin-top: -25px;*/
/*margin-left: -25px;*/
/*width: 50px;*/
/*height: 50px;*/
/*border: 3px solid #ecf0f1;*/
/*line-height: 50px;*/
/*text-align: center;*/
/*border-radius: 100%;*/
/*background: rgba(0,0,0,0.5);*/
/*z-index: 1;*/
/*}*/
/*.tile__details:before {*/
/*content: '▶';*/
/*left: 0;*/
/*width: 100%;*/
/*font-size: 30px;*/
/*margin-left: 7px;*/
/*margin-top: -18px;*/
/*text-align: center;*/
/*z-index: 2;*/
/*}*/
.featured-video_wrapper {
  position: relative;
  height: 350px;
}
.details {
  color: white;
}
button {
  background-color: rgba(51,51,51,.4);
  border-width: 0;
  padding: .75em 2.3em;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 12px;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
}

button:hover {
  background-color: rgba(235, 235, 235, 0.53);
  color: black;
}

@media (max-width: 600px) {
  .cover {
    width: 100%;
    position: relative;
  }

  .tv {
    width:100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .tv .screen {
    position: relative;
  }
  .details {
    position: relative;
    width:92%;
    padding: 4%;
  }
  .details h1{
    margin: 0;
  }
  .featured-video_wrapper {
    height: auto;
  }

}


@media only screen and (max-width: 450px) {
  .tile {
    width: 185px;
    height: 104px;
  }
  .tile-show {
    height: 204px;
  }

  .tile__img {
    width: 185px;
    height: 104px;
  }

  .nav-logo-svg img {
    width: auto;
    display: block;
  }

  .search-input_wrapper {
    position: fixed;
    top: 62px;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 1px solid transparent;
    -webkit-transition: background-color 0.8s;
    -o-transition: background-color 0.8s;
    transition: background-color 0.8s;
    display: none;
  }



  .bg-dark .search-input_wrapper {
    background-color: #000;
    border-top: 1px solid #404040;

  }

  .search-input {
    max-width: 100%;
    color: white;
  }

  .show-dropdown .search-input_wrapper {
    display: block
  }

}



/*REACT MODAL*/


.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.4)!important;
}

.ReactModal__Content {
  width: 100%!important;
  height: 100%!important;
  top: 0!important;
  left:0!important;
  right: 0!important;
  bottom: 0!important;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  right: auto!important;
  bottom: auto!important;
  border-radius: 0!important;
  border: none!important;
  background: rgba(0, 0, 0, 0.85) !important;
  opacity:0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin:0 !important;
  padding: 0 !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.ReactModalPortal {
  position: relative;
  z-index: 3;
}

.ReactModal__Content h2 {
  margin-top: 0;
}

.modal__title {
  font-size: 26px;
  color: white!important;
  font-weight: normal;
  margin-top:0;
}

.modal__link {
  color: white;
  display: block;
}

.modal__close-btn{
  margin-top: 1rem;
}


.signInBtn {
  border: 1px solid grey;
  color: grey;
  text-transform: uppercase;
  padding: .5em 1.5em;
  letter-spacing: 2px;
  cursor: pointer;
  font-size:12px;
}
/*WHEN LOADING IMAGES*/
/*
.row_slider {
  height: 215.25px;
}*/
